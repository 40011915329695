

<section id="tour-des">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="amz-img wow fadeIn" data-wow-duration="2s">
                    <div class="left-side-text-ab mar-p mar-o">
                        <h2>ONE WAY <span>TARIFF</span></h2>
                        <table style="width:100%" colspan="2">
                            <tbody>
                                <tr>
                                    <th>Vehicle Type</th>
                                    <th>Rate/KM</th>
                                    <th>Driver Bata</th>
                                    <th>Additional Charge</th>
                                </tr>
                                <tr *ngFor="let car of cars">
                                    <td>{{car.carType}}</td>
                                    <td>Rs.{{car.oneway}}/KM</td>
                                    <td>Rs.{{car.onewayDriverFee}}</td>
                                    <td>One Way Toll</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">

                <div class="left-side-text-ab mar-p mar-o">
                    <h2>ROUND TRIP <span>TARIFF</span></h2>
                    <table style="width:100%" colspan="2">
                        <tbody>
                            <tr>
                                <th>Vehicle Type</th>
                                <th>Rate/KM</th>
                                <th>Driver Bata</th>
                                <th>Additional Charge</th>
                            </tr>
                            <tr *ngFor="let car of cars">
                                <td>{{car.carType}}</td>
                                <td>Rs.{{car.roundtrip}}/KM</td>
                                <td>Rs.{{car.roundTripDriverFee}}</td>
                                <td>Two Way Toll</td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>

            
        </div>

        <div class="row mt-5">
            <div class="col-lg-12">
                <div class="heading tp_one  icon_dark">
                    <h6>Terms and conditions</h6>
                    <h2>Points before ur travel
                    </h2>
                </div>
                <div class="about_content">
                    <h4> Any Sedan</h4>
                    <p class="description">

                        Swift Dzire Toyota Etios Tata Zest Hyundai Xcent Nissan sunny anyone will be assigned

                    </p>

                    <h4>Any SUV
                    </h4>

                    <p class="description">Innova Xylo Ertiga any one will be assigned

                    </p>

                    <ul>
                        <li>Maximum 4 passenger capacity for Sedan</li>
                        <li>Maximum 7 passenger capacity for SUV</li>

                        <li>Minimum 130km coverage for one way trip</li>
                        <li> Minimum 250km coverage for round trip.</li>
                        <li>One day Round trip close by 11pm at ur pickup </li>
                        <li>Waiting charges Rs.150 per hour in between ur trip.</li>
                        <li>Food break, tea break, & rest room no waiting chrgs.</li>
                        <li>Cancellation charges Rs.300 after driver arrived.</li>
                        <li>Luggage load in carrier Rs.300 if Mention in QT.</li>
                        <li>Note down starting and ending km is must.</li>
                        <li> Permit charges and Driver Bata is same as quoted.</li>
                        <li>Toll charges will be paid thru fastag by Driver.</li>
                        <li>
                            Payment  transfer by online or by cash.
                        </li>
                        <li>
                            EOT calculated running km, Toll by fastag & rest all.
                        </li>
                        <li> Toll fees, interstate Permit, Hill charges, pet charges and waiting charges (if any) are
                            extra
                        </li>
                        <li>For Bangalore minimum 300km per day in Round trip
                        </li>
                        <li>1 day means 1 calendar day(from midnight 12 to next midnight 12)
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </div>
</section>
import { Component, OnInit } from '@angular/core';
import { CARS } from './cars';
import { SITE_SETTINGS } from './settings';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment.prod';
import { getDatabase, ref, set, push, onValue } from "firebase/database";
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const app = initializeApp(environment.firebaseConfig);
const db = getDatabase(app);

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'AIzaSyAZ5NgEh27hXP10pSWetp4wduGnEMCe63A',
    'X-Goog-Api-Key': 'AIzaSyAZ5NgEh27hXP10pSWetp4wduGnEMCe63A'
  })
};

const payload = {
  origin: 'Chennai',
  destination: 'Coimbatore',
  mode: 'driving'
};

const requestBody = {
  "origin": "Chennai",
  "destination": "Coimbatore",
  "travelMode": "DRIVE",
  "extraComputations": ["TOLLS"],
  "routeModifiers": {
    "vehicleInfo": {
      "emissionType": "GASOLINE"
    },
  }
};

// Define the headers
const headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('X-Goog-Api-Key', 'AIzaSyAZ5NgEh27hXP10pSWetp4wduGnEMCe63A')
  .set('X-Goog-FieldMask', 'routes.duration,routes.distanceMeters,routes.travelAdvisory.tollInfo,routes.legs.travelAdvisory.tollInfo');


const body = {
  "origin": {
    "address": "Chennai, Tamil Nadu, India"
  },
  "destination": {
    "address": "Coimbatore, Tamil Nadu, India"
  },
  "travelMode": "DRIVE",
  "extraComputations": ["TOLLS"],
  "routeModifiers": {
    "vehicleInfo": {
      "emissionType": "GASOLINE"
    }
  }
};


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  settings = SITE_SETTINGS;
  tripType = 'One Way';
  cars;
  distance;

  constructor(private dataService: DataService, private http: HttpClient) { }

  ngOnInit(): void {

    const tariffRef = ref(db, `tariff`);

    onValue(tariffRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        let tariff = [];
        Object.keys(data).forEach(key => {
          if (data[key]) {
            data[key]['keyID'] = key;
            tariff.push(data[key]);
          }
        });
        this.dataService.tariff.next(tariff);

      }
    });
  }



  tripTypeSelect(type) {
    this.tripType = type;
  }

}

import { Component } from '@angular/core';
import { tamilnadu_districts } from 'src/app/places';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  cities = tamilnadu_districts;
  
}

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyCRs6_3P1LkjXZIsaJv17wYAtb_UJGZON8",
    authDomain: "cuddalore-droptaxi.firebaseapp.com",
    databaseURL: "https://cuddalore-droptaxi-default-rtdb.firebaseio.com",
    projectId: "cuddalore-droptaxi",
    storageBucket: "cuddalore-droptaxi.appspot.com",
    messagingSenderId: "87986454554",
    appId: "1:87986454554:web:c9d62d33a6abe15b2291a3"
  }
};

import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { DateAdapter, ErrorStateMatcher } from '@angular/material/core';
import { LocationStrategy } from '@angular/common';
import { CARS } from 'src/app/cars';
import { SITE_SETTINGS, TIME } from 'src/app/settings';
import { DataService } from 'src/app/data.service';

import { environment } from 'src/environments/environment.prod';
import { getDatabase, ref, set, push, onValue } from "firebase/database";
import { initializeApp } from 'firebase/app';

const app = initializeApp(environment.firebaseConfig);
const db = getDatabase(app);

@Component({
  selector: 'app-roundtrip',
  templateUrl: './roundtrip.component.html',
  styleUrls: ['./roundtrip.component.css']
})
export class RoundtripComponent implements OnInit {


  @ViewChild('origin', { read: ElementRef }) originElementRef: ElementRef;
  @ViewChild('destination', { read: ElementRef }) destinationElementRef: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChildren('formRow') rows: any;


  showCarsList = false;
  cars;
  defaultCarImage = 'assets/img/icons_select/car_select.svg';
  defaultCarText = 'Select Car type';
  settings = SITE_SETTINGS;
  rideDetails;
  firstFormGroup: FormGroup;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  showDirection = false;
  originCityName;
  destinationCityName;
  origin;
  destination;
  routeDetails;
  pickupPlace;
  dropPlace;
  submitted = false;
  selectedCarType;
  pickupDate;
  pickupTime;
  selectedCar;
  bookingDetails;
  estimation;
  show;
  minDate = new Date();
  days;
  appSettings;
  times = TIME;
  bookingDetailsMail;

  constructor(private _formBuilder: FormBuilder, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private http: HttpClient,
    private location: LocationStrategy, private dateAdapter: DateAdapter<Date>, private dataService: DataService) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {


    this.dataService.tariff.subscribe(res => {
      if (res) {
        this.cars = res;
        console.log(this.cars);
      }
    });

    this.dataService.appSettings.subscribe(res => {
      if (res) {
        this.appSettings = res;
      }
    });



    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
      this.stepper.previous();
    });

    this.mapsAPILoader.load().then(() => {
      const origin = this.originElementRef?.nativeElement;
      const destination = this.destinationElementRef?.nativeElement;
      this.addMapEventListener(origin, 'origin');
      this.addMapEventListener(destination, 'destination');
    });

    this.firstFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]],
      email: ['', [
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ]],
      pickupDate: [''],
      returnDate: [''],
      pickupTime: ['12:00 AM', [Validators.required]]
    });

  }

  get contactValidation() { return this.firstFormGroup.controls; }


  showCarList() {
    this.showCarsList = !this.showCarsList;
  }

  selectCar(car) {
    this.defaultCarImage = car.activeImage;
    this.defaultCarText = car.carType;
    this.selectedCarType = car.carType;
    this.selectedCar = this.cars.filter(car => car.carType === this.selectedCarType)[0];
    this.showCarsList = false
  }

  requestAride() {

    this.submitted = true;

    if (this.firstFormGroup.invalid) {
      return;
    }

    if (!this.origin || !this.destination) {
      return
    }

    if (!this.selectedCar) {
      return
    }



    this.rideDetails = {
      ...this.firstFormGroup.value,
      tripType: 'Round Trip',
      ...this.routeDetails
    }

    this.getEstimation();


  }


  getEstimation() {


    this.show = true;

    if (this.rideDetails.tripType === 'Round Trip') {
      let startDate = moment(moment(this.rideDetails.pickupDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      let returnDate = moment(moment(this.rideDetails.returnDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      this.days = returnDate.diff(startDate, 'days') + 1;
      (this.rideDetails.totalDistance * 2 <= 250
      ) ? this.rideDetails.totalDistance = this.days * 250
        : (this.rideDetails.totalDistance * 2 > 250
          * this.days) ? this.rideDetails.totalDistance = this.rideDetails.totalDistance * 2 : this.rideDetails.totalDistance = 250
          * this.days;
    }

    this.estimation = {
      tripEstimation: this.selectedCar.roundtrip * this.rideDetails.totalDistance + this.selectedCar.roundTripDriverFee * this.days,
      ratePerKM: this.selectedCar.roundtrip,
      driverBatta: this.selectedCar.roundTripDriverFee,
      baseFare: this.rideDetails.totalDistance * +this.selectedCar.roundtrip,
      tripType: this.rideDetails.tripType,
      carType: this.selectedCar.carType,
      distance: this.rideDetails.totalDistance
    }

    this.bookingDetails = {
      ...this.rideDetails,
      ...this.estimation,
      bookingId: Math.random().toString(36).substr(2, 9).toLocaleUpperCase()
    }

    this.bookNow();

    setTimeout(() => {
      this.stepper.next();
    }, 300);



  }

  bookNow() {

    this.bookingDetailsMail = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'origin': this.bookingDetails.origin,
      'originCityName': this.bookingDetails.originCityName,
      'destination': this.bookingDetails.destination,
      'destinationCityName': this.bookingDetails.destinationCityName,
      'distance': +this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': moment(this.bookingDetails.pickupDate).format('MMMM Do YYYY'),
      'displayOnlyMonth': moment(this.bookingDetails.pickupDate).format('MMM'),
      'displayOnlyDate': moment(this.bookingDetails.pickupDate).format('DD'),
      'displayOnlyYear': moment(this.bookingDetails.pickupDate).format('YYYY'),
      'bookedTime': this.bookingDetails.pickupTime,
      'baseFare': this.bookingDetails.baseFare,
      'estimate': this.bookingDetails.tripEstimation,
      'duration': this.bookingDetails.totalDuration,
      'tripType': this.bookingDetails.tripType,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': moment(this.bookingDetails.returnDate).format('MMMM Do YYYY'),
      'adminEmailSubject': `Website Enquiry From ${this.bookingDetails.name}`,
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNo,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
    }

    this.submitted = false;

    this.http.post(this.settings.emailAPI, this.bookingDetailsMail).subscribe(res => {
      console.log('enquiry email sent successfully')
    });
  }

  confirm() {
    this.bookingDetailsMail = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'origin': this.bookingDetails.origin,
      'originCityName': this.bookingDetails.originCityName,
      'destination': this.bookingDetails.destination,
      'destinationCityName': this.bookingDetails.destinationCityName,
      'distance': +this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': moment(this.bookingDetails.pickupDate).format('MMMM Do YYYY'),
      'displayOnlyMonth': moment(this.bookingDetails.pickupDate).format('MMM'),
      'displayOnlyDate': moment(this.bookingDetails.pickupDate).format('DD'),
      'displayOnlyYear': moment(this.bookingDetails.pickupDate).format('YYYY'),
      'bookedTime': this.bookingDetails.pickupTime,
      'baseFare': this.bookingDetails.baseFare,
      'estimate': this.bookingDetails.tripEstimation,
      'duration': this.bookingDetails.totalDuration,
      'tripType': this.bookingDetails.tripType,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': moment(this.bookingDetails.returnDate).format('MMMM Do YYYY'),
      'adminEmailSubject': `Confirmed Booking From ${this.bookingDetails.name}`,
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNo,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
    }
    const websiteBookingsRef = ref(db, 'bookings');
    const newWebsiteBookingsRefRef = push(websiteBookingsRef);

    this.submitted = false;

    this.http.post(this.settings.emailAPI, this.bookingDetailsMail).subscribe(res => {
      console.log('enquiry email sent successfully')
    });

    set(newWebsiteBookingsRefRef, this.bookingDetailsMail).then(res => {
      setTimeout(() => {
        this.stepper.next();
      }, 300);
    });


    let returnDate = `${(this.bookingDetailsMail.tripType === 'One Way') ? '' : 'Return Date:' + this.bookingDetailsMail.returnDate}`

    let smsMessage = `
    Thanks for Choosing ${this.settings.siteName}.
    Your Booking Details:\r\n
    Booking ID: ${this.bookingDetailsMail.bookingId}\r\n
    Name: ${this.bookingDetailsMail.name}\r\n
    Mobile Number: ${this.bookingDetailsMail.phoneNo}\r\n
    Pickup Location: ${this.bookingDetailsMail.origin}\r\n
    Drop Location: ${this.bookingDetailsMail.destination} \r\n
    Pickup Date/Time: ${this.bookingDetailsMail.bookedDate} ${this.bookingDetailsMail.bookedTime} \r\n
    ${returnDate} \r\n
    Trip Type: ${this.bookingDetailsMail.tripType} \r\n
    Total KM: ${this.bookingDetailsMail.distance} KM\r\n
    Total Trip Fare: ${this.bookingDetailsMail.estimate} ₹\r\n
    Rate Per KM: ${this.bookingDetailsMail.ratePerKM} ₹\r\n
    Driver Batta: ${this.bookingDetailsMail.driverBatta} ₹\r\n
    Toll : ${(this.bookingDetails.tollCost) ? 'INCLUDED' : 'EXTRA'} \r\n 
    For any questions please contact ${this.settings.phoneNo} \r\n 
    https://${this.settings.websiteAddress}`


    let whatsappMessage = window.encodeURIComponent(smsMessage);


    let whatsupText = `https://api.whatsapp.com/send?phone=${this.settings.whatsapp}&text=${whatsappMessage}`;
    window.open(whatsupText, "_blank");
    this.stepper.next();
    this.scrollToTop();
  }

  home() {
    window.open(`https://${this.settings.websiteAddress}`, '_self');
  }




  scrollToTop() {
    const container = document.querySelector('#booking');
    if (container) {
      container.scrollTop = 0;
    }
  }


  addMapEventListener(input, type) {

    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setComponentRestrictions({
      country: ["in"],
    });
    autocomplete.addListener("place_changed", () => {

      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        (type === 'origin') ? this.origin = place.formatted_address : this.destination = place.formatted_address;
        (type === 'origin') ? this.originCityName = place.name : this.destinationCityName = place.name;


        if (this.origin && this.destination) {
          this.showDirection = true;
          this.pickupPlace = this.origin;
          this.dropPlace = this.destination;
        }

        this.zoom = 12;
      });
    });
  }

  onResponses(event) {
    this.routeDetails = {
      'origin': this.origin,
      'destination': this.destination,
      'originCityName': this.originCityName,
      'destinationCityName': this.destinationCityName,
      'totalDistance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, ""),
      'totalDuration': event.routes[0].legs[0].duration.text
    }
  }

}

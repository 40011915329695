export const CARS = [
    {
      carType: 'Sedan',
      'oneway': 13,
      'roundtrip': 12,
      'sedanRental': 25,
      'etiosRental': 30,
      'suvRental': 40,
      'innovaRental': 45,
      'crystaRental': 50,
      'onewayDriverFee': 400,
      'roundTripDriverFee': 400,
      availableCars: 'SWIFT DZIRE, XCENT, AND SIMILAR',
      passengers: 4,
      image: 'https://images.ctfassets.net/509kpi6dw56l/6QdZLGkGnMv0F8fDwFMGlE/1b85e10c7d90fdac89a4794938a43780/prime-play-1.png?h=250',
      activeImage: 'https://images.ctfassets.net/509kpi6dw56l/1jwgHXg6XJ6uog96Dh2BVd/317481d3bd8a7f97ee19d8db7b5e9b95/prime-play-1-1.png?h=250',
      h50kmFare: 1200,
      h100kmFare: 2200,
      h150kmFare: 3200,
      airportCostPerKM: 35,
      rentalCostPerKm: 25
    },
    {
      carType: 'Etios',
      'oneway': 14,
      'roundtrip': 12,
      'sedanRental': 25,
      'etiosRental': 30,
      'suvRental': 40,
      'innovaRental': 45,
      'crystaRental': 50,
      'onewayDriverFee': 400,
      'roundTripDriverFee': 400,
      availableCars: 'SWIFT DZIRE, XCENT, AND SIMILAR',
      passengers: 4,
      image: 'https://images.ctfassets.net/509kpi6dw56l/7s8MKkYx4bSfPYlrqUEAW5/c23e3f9a5f9ebbbca681d63f0e8b8130/lux-1.png?h=250',
      activeImage: 'https://images.ctfassets.net/509kpi6dw56l/2xxEOELbZtfsJPYIlhbuhK/0b7a9d17525dea250d2f42cde3352fec/lux-1-1.png?h=250'
      ,
      h50kmFare: 1200,
      h100kmFare: 2200,
      h150kmFare: 3200,
      airportCostPerKM: 40,
      rentalCostPerKm: 30
    },
    {
      carType: 'Suv',
      'oneway': 18,
      'roundtrip': 16,
      'sedanRental': 25,
      'etiosRental': 30,
      'suvRental': 40,
      'innovaRental': 45,
      'crystaRental': 50,
      'onewayDriverFee': 400,
      'roundTripDriverFee': 400,
      availableCars: 'TAVERA, XYLO, LODGY AND SIMILAR',
      passengers: 6,
      image: 'https://images.ctfassets.net/509kpi6dw56l/ZV8ALWfK2zWiQLJ2gep4N/010ed551e2211627602d6d6e3f1f4364/lux-1__2_.png?h=250',
      activeImage: 'https://images.ctfassets.net/509kpi6dw56l/79k5lRRg2OQzN8dFrBIXH5/8ef9426846d366b8df4f1a3f913c4503/lux-1__3___1_.png?h=250'
      ,
      h50kmFare: 1500,
      h100kmFare: 4000,
      h150kmFare: 4000,
      airportCostPerKM: 45,
      rentalCostPerKm: 35
    },
    {
      carType: 'Innova',
      'oneway': 19,
      'roundtrip': 17,
      'sedanRental': 25,
      'etiosRental': 30,
      'suvRental': 40,
      'innovaRental': 45,
      'crystaRental': 50,
      'onewayDriverFee': 400,
      'roundTripDriverFee': 400,
      availableCars: 'TAVERA, XYLO, LODGY AND SIMILAR',
      passengers: 6,
      image: 'https://images.ctfassets.net/509kpi6dw56l/72yoz2W0gPFPq50SfgPPqU/2c521cd2260cff9246ea2955bc37b707/prime-suv-1.png?h=250',
      activeImage: 'https://images.ctfassets.net/509kpi6dw56l/4BttjSr5H4GMEi6gDzq8hP/013408e51a5ac899cf05f07e708d254e/prime-suv-1-1.png?h=250'
      ,
      h50kmFare: 1700,
      h100kmFare: 4000,
      h150kmFare: 4500,
      airportCostPerKM: 50,
      rentalCostPerKm: 40
    }
  ];
import { MapsAPILoader } from '@agm/core';
import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, NgZone, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment';
import { CARS } from 'src/app/cars';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-bookingform',
  templateUrl: './bookingform.component.html',
  styleUrls: ['./bookingform.component.css']
})
export class BookingformComponent {

}

import { Component } from '@angular/core';
import { footer, tamilnadu_districts } from 'src/app/places';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
settings = SITE_SETTINGS;
cities = tamilnadu_districts;
footerCity = footer;
}

<section id="about-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="common-banner-text wow zoomIn" data-wow-duration="2s"
                    style="visibility: visible; animation-duration: 2s; animation-name: zoomIn;">
                    <div class="common-heading">
                        <h1>FAQ's</h1>
                    </div>
                    <div class="commom-sub-heading">
                        <h6>
                            <a href="index.html">Home</a>
                            <span>/</span>
                            <a href="#!">FAQ's</a>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section id="faqws-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="all-faq-cover">
                    <div class="acrond-heading">
                        <h6>Payment</h6>
                    </div>
                    <div id="accordion">
                        <div class="card wow fadeIn" data-wow-duration="1s"
                            style="visibility: visible; animation-duration: 1s; animation-name: fadeIn;">
                            <div class="card-header" id="heading-1">
                                <h5 class="mb-0">
                                    <a class="collapsed" role="button" data-toggle="collapse" href="#collapse-1"
                                        aria-expanded="false" aria-controls="collapse-2">
                                        How can I book a cab?
                                    </a>
                                </h5>
                            </div>
                            <div id="collapse-1" class="collapse" data-parent="#accordion" aria-labelledby="heading-1"
                                style="">
                                <div class="card-body">
                                    <p>Booking a {{settings.siteName}} taxi is extremely easy. You can book online by visiting our
                                        website or downloading the {{settings.siteName}} app to book your ride from anywhere at your
                                        convenience. You can also call on {{settings.phoneNo}} to reach out to our
                                        customer service team to assist you with a customised itinerary.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card  wow fadeIn" data-wow-duration="1.5s"
                            style="visibility: visible; animation-duration: 1.5s; animation-name: fadeIn;">
                            <div class="card-header" id="heading-2">
                                <h5 class="mb-0">
                                    <a class="collapsed" role="button" data-toggle="collapse" href="#collapse-2"
                                        aria-expanded="false" aria-controls="collapse-3">
                                        Do I have to pay a full charge or is there any one-way fare?
                                    </a>
                                </h5>
                            </div>
                            <div id="collapse-2" class="collapse" data-parent="#accordion" aria-labelledby="heading-2">
                                <div class="card-body">
                                    <p>
                                        At {{settings.siteName}}, we offer both one-way and round-trip packages. You can pay according
                                        to your trip plan. So, you have to pay the fare for one-side journey only.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card  wow fadeIn" data-wow-duration="2s"
                            style="visibility: visible; animation-duration: 2s; animation-name: fadeIn;">
                            <div class="card-header" id="heading-3">
                                <h5 class="mb-0">
                                    <a class="collapsed" role="button" data-toggle="collapse" href="#collapse-3"
                                        aria-expanded="false" aria-controls="collapse-3">
                                        Can I pick a car model of my own choice ?
                                    </a>
                                </h5>
                            </div>
                            <div id="collapse-3" class="collapse" data-parent="#accordion" aria-labelledby="heading-3">
                                <div class="card-body">
                                    <p>Yes, you may pick a car model of your choice from our available fleet of sedans,
                                        hatchbacks and SUVs, for your journey </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
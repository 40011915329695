import { Component } from '@angular/core';
import { CARS } from 'src/app/cars';
import { DataService } from 'src/app/data.service';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.css']
})
export class TariffComponent {
  settings = SITE_SETTINGS;
  cars;
  constructor( private dataService: DataService) {
    this.dataService.tariff.subscribe(res => {
      if (res) {
        this.cars = res;
        console.log(this.cars);
      }
    });

  };
}

import { Component, OnInit } from '@angular/core';
import { CARS } from 'src/app/cars';
import { POPULAR_ROUTES, top_cities } from 'src/app/places';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  settings = SITE_SETTINGS;
  cars = CARS;
  selectedCar;
  poplarRoutes = POPULAR_ROUTES as any;
 topCities = top_cities;
 pupular = POPULAR_ROUTES as any;
 
  ngOnInit() {
    this.getRouteEstimation();

  }

  getRouteEstimation() {
    this.selectedCar =  CARS[0];
     this.poplarRoutes.map(route => {
       route['estimation'] = Math.round(this.selectedCar.oneway * route.distance + this.selectedCar.onewayDriverFee);
     });
   }
}

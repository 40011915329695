import { Component } from '@angular/core';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  settings = SITE_SETTINGS;
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    uid = new BehaviorSubject(null);
    driverDetails = new BehaviorSubject(null);
    newBookings = new BehaviorSubject(null);
    activeTrips = new BehaviorSubject(null);
    completedTrips = new BehaviorSubject(null);
    canceledTrips = new BehaviorSubject(null);
    websiteBookings = new BehaviorSubject(null);
    appSettings = new BehaviorSubject(null);
    tariff = new BehaviorSubject(null);
}

import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { CanonicalService } from 'src/app/canonical.service';
import { CARS } from 'src/app/cars';
import { allCities } from 'src/app/places';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-city-detail',
  templateUrl: './city-detail.component.html',
  styleUrls: ['./city-detail.component.css']
})
export class CityDetailComponent implements OnInit {

  title;
  settings = SITE_SETTINGS;
  places = allCities;
  mainCity;
  origin;
  destination;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  totalDistance;
  totalDuration;
  showDirection;
  estimations = [];
  routeDetails;
  showEstimation = false;
  otherCities;
  hideSpinner = false;
  days = 1;
  tripType = 'One Way';
  rideDetails;
  oneWayFormGroup: any;
  submitted = false;
  minDate = new Date();
  showCars = false;
  bookingDetails;
  pickupDate;
  returnDate;
  cars = CARS as any;
  
  constructor(private activeRoute: ActivatedRoute, private canonicalService: CanonicalService, private titleService: Title, private metaTagService: Meta) {

  }

  ngOnInit(): void {



    this.canonicalService.setCanonicalURL();
    let routeUrl = this.activeRoute.snapshot.paramMap.get('cityRoute').split('-');
    this.origin = routeUrl[0].charAt(0).toUpperCase() + routeUrl[0].slice(1);
    this.destination = routeUrl[2].charAt(0).toUpperCase() + routeUrl[2].slice(1);

    (this.origin && this.destination) ? this.showDirection = true : this.showDirection = false;

    console.log(this.origin, this.destination);

    this.title = `${this.origin} to ${this.destination} One Way Taxi - Book Your Ride with Tamil Nadu One Way Taxi`;
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: `Looking for a reliable one-way taxi from ${this.origin} to ${this.destination}? Book with Tamil Nadu One Way Taxi for comfortable, safe, and affordable travel. Get in touch now!` }
    );


  }



  onResponses(event) {
    this.routeDetails = {
      'tripType': this.tripType,
      'origin': this.origin,
      'destination': this.destination,
      'originCityName': this.origin,
      'destinationCityName': this.destination,
      'totalDistance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, ""),
      'totalDuration': event.routes[0].legs[0].duration.text,
      'distance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, "")
    }

    this.getEstimation(this.routeDetails);
    this.rideDetails = this.routeDetails;
    this.showCars = true;
  }

  getEstimation(routes) {

    let distance;

    if (routes.tripType === 'One Way') {
      distance = (routes.distance <= 130) ? routes.distance = 130 : routes.distance;
    }

    if (routes.tripType === 'Round Trip') {
      let startDate = moment(moment(routes.pickupDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      let returnDate = moment(moment(routes.returnDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      this.days = returnDate.diff(startDate, 'days') + 1;
      distance = (routes.distance * 2 <= 250) ? routes.distance = this.days * 250 : (routes.distance * 2 > 250 * this.days) ? routes.distance = routes.distance * 2 : routes.distance = 250 * this.days;
    }
    this.cars.forEach(car => {
      car['tripEstimation'] = (routes.tripType === 'One Way') ? car.oneway * distance + car.onewayDriverFee : car.roundtrip * distance + car.roundTripDriverFee * this.days;
      car['driverBatta'] = (routes.tripType === 'One Way') ? car.onewayDriverFee : car.roundTripDriverFee * this.days;
      car['ratePerKM'] = (routes.tripType === 'One Way') ? car.oneway : car.roundtrip;
      car['distance'] = routes.distance;
      car['baseFare'] = (routes.tripType === 'One Way') ? car.oneway * distance : car.roundtrip * distance;
      car['tripType'] = routes.tripType;
    });
  }


  
}

import { Component } from '@angular/core';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent {
settings = SITE_SETTINGS;
}
